<template>

  <b-card title="Hidden Endpoint Results 🙌">

    <b-card-text>Check out below.</b-card-text>



<div>
  <!-- search input -->
  <div class="custom-search d-flex justify-content-end">
    <b-form-group>
      <div class="d-flex align-items-center">
        <label class="mr-1">Search</label>
        <b-form-input
          v-model="searchTerm"
          placeholder="Search"
          type="text"
          class="d-inline-block"
        />
      </div>
    </b-form-group>
  </div>

  <!-- table -->
  <vue-good-table
    :line-numbers="true"
    :columns="columns"
    :rows="rows"
    :rtl="direction"
    
    :search-options="{
      enabled: true,
      externalQuery: searchTerm }"
    :select-options="{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
    :pagination-options="{
      enabled: true,
      perPage:pageLength
    }"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >

      <!-- Column: Name -->
      <span
        v-if="props.column.field === 'finding'"
        class="text-nowrap"
      >

        <span class="text-nowrap">{{ props.row.finding }}</span>
      </span>

 
 


    

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    

    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1">
          <span class="text-nowrap ">
            Showing 1 to
          </span>
          <b-form-select
            v-model="pageLength"
            :options="['3','5','10']"
            class="mx-1"
            @input="(value)=>props.perPageChanged({currentPerPage:value})"
          />
          <span class="text-nowrap"> of {{ props.total }} entries </span>
        </div>
        <div>
          <b-pagination
            :value="1"
            :total-rows="props.total"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="(value)=>props.pageChanged({currentPage:value})"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </vue-good-table>
</div>
</b-card>

</template>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<script>
import {
BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

import store from '@/store/index'


// minutes ago
//import moment from 'moment';

//dompurify prevent xss
import * as DOMPurify from 'dompurify';


export default {
components: {
  VueGoodTable,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardText,
},
data() {

  
  return {
    pageLength: 10,
    dir: false,
    columns: [
      {
        label: 'Name',
        field: 'finding',
      }
    ],
    rows: [],
    searchTerm: '',
    
  }
},

methods : {

},

computed: {


  direction() {
    if (store.state.appConfig.isRTL) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = true
      return this.dir
    }
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    this.dir = false
    return this.dir
  },
},
created() {

  //fetches userapikey,scandomain,scanid parameters from user cookie
 // const userapikey = this.$route.query.userapikey
 // const scandomain = this.$route.query.scandomain
 // const scanid = this.$route.query.scanid

 const userapikey =  localStorage.getItem('userapikey')
 const scandomain = localStorage.getItem('scandomain')
 const scanid = localStorage.getItem('scanid')

  let userapikeysanitized = DOMPurify.sanitize(userapikey);
  let scandomainsanitized = DOMPurify.sanitize(scandomain);
  let scanidsanitized = DOMPurify.sanitize(scanid);



  this.$http.get(`${process.env.VUE_APP_SECURITYINFINITYFRONTEND_URL}`+'/api/report_dashboard/fetchreport?reporttype=hiddenendpointsfinder&userapikey='+userapikeysanitized+'&scandomain='+scandomainsanitized+'&scanid='+scanidsanitized)
    .then(res => { this.rows = res.data })
},
}
</script>